<template>
    <div class="row">
        <div class="col-md-3 mb-4 position-relative" v-for="(module, index) in modules"
             :key="`card-module-${module.id}${index}`">
            <CardModule
                :module="module"
                :index-module="index"
                @edit-module="editModule"
            />
        </div>
    </div>
</template>

<script>
import CardModule from "@/views/mentoring/register/card-module.vue";
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {mapState} from "vuex";

export default {
    components: {
        CardModule
    },
    data() {
        return {
            show_details: false,
            color: null,
            mouseOn: false,
        }
    },

    methods: {
        editModule(indexModule) {
            this.$emit('edit-module', indexModule);
        }
    },

    created() {
        var style = document.createElement('style');
        style.type = 'text/css';

        var css = `
                .slick-prev:before, .slick-next:before {
                color: rgb(128, 128, 128) !important;
                }

                .col.position-relative.pointer {
                      transition: transform 0.6s ease-in-out;
                }

                .col.position-relative.pointer:hover {
                      transform: scale(1.1);
                }

                .top-middle {
                    top: calc(50% - 15px)
                }
        `;

        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        document.head.appendChild(style);
        this.color = document.getElementById('mentoring_color');
    },

    computed: {
        ...mapState({
            modules: state => state.mentoring.modules
        })
    },
}
</script>
