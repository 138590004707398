<template>
    <div class="card p-3">

        <div class="row mb-3" v-show="!show_module && indexContent === null">
            <div class="col-md-4 text-center">
                <b-skeleton-img
                    v-if="!loaded"
                    style="aspect-ratio: 320 / 480;"
                />
                <img v-show="loaded" :src="module.imgUrl" alt="generica_modulo" class="img-fluid img-module"
                     @load="loaded=true">
                <span v-if="module.show_title" :style="
                            `
                             position: absolute;
                             bottom: 20px;
                             z-index: 1000;
                             font-size: 16px;
                             font-weight: bold;
                             line-height: 1;
                             top: 380px;
                             left: 15px;
                             right: 15px;
                             color: ${module.mentoring_color ? color : '#FFF'}
                            `">
                        {{ module.title }}
                    </span>
                <div class="d-flex justify-content-center top-middle mt-3">
                    <span class="bg-soft-info rounded-circle text-center balloon pointer"
                          @click="show_module=true" title="Editar Módulo">
                        <i class="fa fa-pencil-alt text-info"></i>
                    </span>
                </div>
            </div>

            <div class="col-md-8">
                <div class="col-12 text-end" v-show="!show_drag">
                    <i class="fa fa-sort pointer" @click="show_drag=true"></i>
                </div>
                <div class="row" v-if="!show_drag">
                    <div class="col-12">
                        <div class="row position relative"
                             v-for="(content, i) in module.contents"
                             :key="'collapse-item-' + content.id" @mouseenter="mouseOn = content.id"
                             @mouseleave="mouseOn = null">
                            <div class="col-12 mb-3 d-flex" :class="{'opacity-25': mouseOn === content.id}">
                                <i class="fa fa-video mr-2 my-auto" v-if="content.type == 1"></i>
                                <i class="fa fa-download mr-2 my-auto" v-else-if="content.type == 2"></i>
                                <i class="fa fa-question-circle mr-2 my-auto" v-else-if="content.type == 3"></i>
                                <i class="fa fa-question-circle mr-2 my-auto" v-else-if="content.type == 4"></i>
                                <i class="fa fa-calendar mr-2 my-auto" v-else-if="content.type == 5"></i>
                                <i class="fa fa-file mr-2 my-auto" v-else-if="content.type == 6"></i>
                                <span class="font-size-16pt">
                                    {{ content.subject }}
                                </span>
                            </div>

                            <BCol class="position-absolute d-flex justify-content-center" v-if="mouseOn === content.id"
                                  :style="`top: ${i * 40 - 10}px`">
                                <span class="bg-soft-info rounded-circle mx-2 my-auto text-center balloon pointer"
                                      @click="setContent(i); mouseOn = null">
                                    <i class="fa fa-pencil-alt text-info"></i>
                                </span>
                                <span class="bg-soft-danger rounded-circle mx-2 my-auto text-center balloon pointer"
                                      @click="removeContent(i)">
                                    <i class="fa fa-trash text-danger"></i>
                                </span>
                            </BCol>

                        </div>
                    </div>
                </div>

                <PreviewDrag
                    v-else
                    @cancel="show_drag=false"
                    @update-contents="updateContents"
                    :index-module="indexModule"
                />

            </div>

            <div class="col-12" v-if="!show_module && !show_content && !show_drag && indexContent === null">
                <div class="row">
                    <div class="col-12 text-end mt-3">
                        <button type="button" class="btn btn-outline-danger" @click="cancel">Cancelar</button>
                        <button type="button" class="btn btn-outline-success ml-2" @click="save">
                            Salvar alterações
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <PreviewEditModule
            v-if="show_module"
            :module="module"
            @update-module="updateModule"
            @cancel="show_module=false"
        />

        <PreviewEditContent
            v-if="indexContent !== null"
            :content="content"
            @cancel="indexContent=null"
            @update-content="updateContent"
        />


    </div>
</template>
<!-- eslint-disable -->
<script>
import Cropper from "@/components/base/cropper.vue";
import PreviewEditModule from "@/views/mentoring/register/preview-edit-module.vue";
import PreviewEditContent from "@/views/mentoring/register/preview-edit-content.vue";
import PreviewDrag from "@/views/mentoring/register/preview-drag.vue";
import Vue from "vue";

export default {
    components: {
        Cropper,
        PreviewEditModule,
        PreviewDrag,
        PreviewEditContent,
    },
    props: {
        indexModule: {
            required: true,
            type: Number
        }
    },

    data() {
        return {
            module: {},
            content: {},
            visible: true,
            loaded: false,
            color: null,
            show: false,
            show_module: false,
            show_content: false,
            show_alert: false,
            show_drag: false,
            mouseOn: null,
            indexContent: null
        }
    },

    methods: {
        async getData() {
            this.module = JSON.parse(JSON.stringify(this.$store.state.mentoring.modules[this.indexModule]));
        },

        updateModule(payload) {
            this.module = {
                ...this.module,
                ...payload
            };
            this.show_module = false;
        },

        updateContent(payload) {
            this.module.contents[this.indexContent] = payload;
            this.indexContent = null;
        },

        updateContents(payload) {
            this.module.contents = [...payload];
            this.show_drag = false;
        },

        toggleVisibility() {
            this.visible = !this.visible;
        },

        cancel() {
            this.$emit('cancel');
        },

        save() {
            Vue.set(this.$store.state.mentoring.modules, this.indexModule, this.module);
            this.$emit('cancel');
        },

        removeContent(i) {
            console.log(i)
        },

        setContent(i) {
            this.content = this.module.contents[i];
            this.indexContent = i;
        }

    },

    watch: {
        indexModule(newValue, oldValue) {
            this.getData();
            this.show = false;
            this.show_module = false;
            this.show_content = false;
            this.show_alert = false;
        }
    },

    mounted() {
        this.getData();
    },

    created() {
        this.color = document.getElementById('mentoring_color');
    }
}
</script>
