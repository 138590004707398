<template>
    <div class="col-12">
        <div class="row">
            <div class="col-md-4 d-flex mb-3">
                <i
                    class="material-icons text-70 icon-16pt icon--left mt-auto mb-2">drag_handle</i>
                <div class="w-100">
                    <label>Título</label>
                    <input type="text" class="form-control" required
                           placeholder="Assunto da Atividade/Conteúdo"
                           v-model="copy.subject">
                </div>
            </div>

            <div class="col-md-2 mb-3">
                <label>Tipo</label>
                <select class="form-control" required v-model="copy.type">
                    <option value="">Selecionar</option>
                    <option value="1">Vídeo</option>
                    <option value="2">Arquivo</option>
                    <option value="3">Formulário</option>
                    <option value="4">Atividade</option>
                    <option value="5">Agenda</option>
                    <option value="6">Leitura</option>
                </select>
            </div>

            <div class="col-md-5 mb-3" v-if="copy.type == 1">
                <label>Link</label>
                <input class="form-control mr-2" type="text" placeholder="Link do Vídeo"
                       v-model="copy.video" required>
            </div>

            <div class="col-md-4 mb-3" v-if="copy.type == 2">
                <label v-if="!copy.url">Arquivo</label>
                <label v-else>Trocar Arquivo</label>
                <input class="form-control mr-2"
                       type="file" required :id="'doc-' + copy.id"
                       @change="handleFileUpload"
                       v-if="copy.type == 2">
            </div>
            <div class="col-md-2 mb-4 mt-auto" v-if="copy.type == 2 && copy.url">

                <a :href="copy.url" data-toggle="tooltip" title="Visualizar arquivo"
                   class="copy-file" target="_blank">
                    <i class="fas fa-download"></i>
                </a>
            </div>


            <div class="col-md-4 mb-3" v-else-if="copy.type == 3">
                <label>Formulário</label>
                <select class="form-control mr-2" required v-model="copy.form_id"
                        v-if="copy.type == 3">
                    <option value="">Selecione o Formulário</option>
                    <option v-for="form in forms" :key="'form-option-' + form.id"
                            :value="form.id">
                        {{ form.title }}
                    </option>
                </select>
            </div>
            <div class="col-md-1 mb-4 mt-auto" v-if="copy.type == 3 && copy.form_id && copy.form_id !== ''">
                <a :href="`${env.menteeUrl}formulario/preview/${encondeId(copy.form_id)}`"
                   target="_blank">
                    <i class="fa fa-search text-muted mx-1" title="Preview"></i>
                </a>
            </div>
            <div class="col-md-12 mb-3" v-else-if="copy.type == 6">
                <label>Descrição</label>
                <vue-editor class="editorSize" v-model="copy.read"
                            required :editor-toolbar="customToolbar"/>
            </div>

            <div style="display: contents" v-else-if="copy.type == 4">
                <div class="col-md-12 mb-3">
                    <label>Descrição</label>
                    <vue-editor class="editorSize" v-model="copy.activity.description"
                                required :editor-toolbar="customToolbar"/>
                </div>

                <div class="col-md-4 mb-3">
                    <label class="form-label">Tipo <span class="text-danger">*</span></label>
                    <select name="category" v-model="copy.activity.type" class="form-control">
                        <option value="">Selecione</option>
                        <option value="1">Dissertativa</option>
                        <option value="2">Única escolha</option>
                        <option value="3">Multipla escolha</option>
                        <option value="4">Upload de arquivo</option>
                    </select>
                </div>
                <div class="col-md-12 mb-3"
                     v-if="copy.activity.type == '2' || copy.activity.type == '3'">
                    <label class="form-label">Alternativas </label>
                    <input
                        class="form-control"
                        v-model="currentAlternative"
                        @keyup.enter="addAlternative()"
                        @keydown.tab.prevent="addAlternative()"
                        placeholder="Digite a alternativa e precione Enter OU Tab..."
                    />
                </div>

                <BCol sm="12">
                    <BBadge variant="light" class="w-100 text-start p-2 mb-1 d-flex justify-content-between"
                            v-for="(a, i) in copy.activity.alternatives"
                            :key="`alternative-` + a.id">
                        <span class="fs-12" style="white-space: break-spaces">{{ a.alternative }}</span>
                        <div class="my-auto">
                            <i class="fa fs-12 fa-times-circle my-auto text-muted pointer"
                               @click="removeAlternative(i)"></i>
                        </div>
                    </BBadge>
                </BCol>
            </div>

            <div class="col-12 text-end mt-3">
                <button type="button" class="btn btn-outline-danger" @click="cancel">Cancelar</button>
                <button type="button" class="btn btn-outline-success ml-2" @click="save">
                    Confirmar
                </button>
            </div>


        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
import {VueEditor} from "vue2-editor";
import {encondeId} from "@/components/composables/functions";
import env from "@/env";
import http from "@/http";

export default {
    components: {
        VueEditor
    },
    props: {
        content: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            copy: [],
            env,
            customToolbar: [
                [{'size': ['small', false, 'large', 'huge']}],
                ["bold", "italic"],
                [{list: "ordered"}, {list: "bullet"}],
            ],
            currentAlternative: '',
            forms: [],
        }
    },

    methods: {
        encondeId,

        cancel() {
            this.$emit('cancel');
        },

        save() {
            this.$emit('update-content', this.copy);
        },

        getData() {
            this.copy = JSON.parse(JSON.stringify(this.content));
            if (this.copy.activity === null) {
                this.copy.activity = {
                    id: new Date().getTime() + '_activity',
                    description: '',
                    type: '',
                    alternatives: []
                };
            }
        },

        addAlternative() {
            if (this.currentAlternative.trim() === '') {
                this.$notifyError('Você precisa adicionar a alternativa!');
                return;
            }

            this.copy.activity.alternatives.push({
                id: new Date().getTime() + '_alter',
                alternative: this.currentAlternative
            });

            this.currentAlternative = '';
        },

        removeAlternative(i) {
            this.copy.activity.alternatives.splice(i, 1);
        },

        handleFileUpload(event) {
            const arr = event.target.id.split('-');
            const file = event.target.files[0];
            const fileUrl = URL.createObjectURL(file);

            this.module.copys[arr[1]].doc = event.target.files[0];
            this.module.copys[arr[1]].url = fileUrl;
        },

        getForms() {
            http.get('forms/?paginated=false')
                .then(response => {
                    this.forms = response.data.data
                    this.$emit('update-forms', response.data.data);
                })
        },
    },

    mounted() {
        this.getData();
        this.getForms();
    },

    watch: {
        indexContent(newValue, oldValue) {
            this.getData();
            this.show = false;
            this.show_module = false;
            this.show_copy = false;
            this.show_alert = false;
        }
    },
}

</script>
