<template>
    <Layout>
        <div id="page">
            <div class="py-32pt card">
                <div class="container page__container">
                    <div class="progression-bar progression-bar--active-primary">

                        <div class="progression-bar__item"
                             :class="{'progression-bar__item--complete progression-bar__item--complete': progress > 0,
                                    'progression-bar__item--active': progress === 0
                            }"
                        >
                                <span class="progression-bar__item-content mx-auto" @click="progress=0">
                                    <i class="material-icons progression-bar__item-icon" v-if="progress > 0">done</i>
                                    <i class="material-icons progression-bar__item-icon" v-else></i>
                                    <span
                                        class="progression-bar__item-text h5 mb-0 text-uppercase">Informações Básicas</span>
                                </span>
                        </div>

                        <div
                            class="progression-bar__item"
                            :class="{'progression-bar__item--complete': progress > 1,
                                    'progression-bar__item--active progression-bar__item--complete': progress === 1
                            }"
                        >
                                <span class="progression-bar__item-content mx-auto" @click="progress=1">
                                    <i class="material-icons progression-bar__item-icon" v-if="progress > 1">done</i>
                                    <i class="material-icons progression-bar__item-icon" v-else></i>
                                    <span
                                        class="progression-bar__item-text h5 mb-0 text-uppercase">Detalhes</span>
                                </span>
                        </div>

                        <div
                            class="progression-bar__item"
                            :class="{'progression-bar__item--complete': progress > 2,
                                    'progression-bar__item--active progression-bar__item--complete': progress === 2
                            }"
                        >
                                <span class="progression-bar__item-content mx-auto" @click="progress=2">
                                    <i class="material-icons progression-bar__item-icon" v-if="progress > 2">done</i>
                                    <i class="material-icons progression-bar__item-icon" v-else></i>
                                    <span
                                        class="progression-bar__item-text h5 mb-0 text-uppercase">Mídia</span>
                                </span>
                        </div>

                        <div class="progression-bar__item"
                             :class="{'progression-bar__item--active progression-bar__item--complete': progress === 3}"
                        >
                                <span class="progression-bar__item-content mx-auto" @click="progress=3">
                                    <i class="material-icons progression-bar__item-icon"></i>
                                    <span
                                        class="progression-bar__item-text h5 mb-0 text-uppercase">Módulos</span>
                                </span>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row my-3" v-if="progress < 3 || !addModule && type === 'list' && !preview">
                <div class="col text-center">
                    <button type="button" class="btn btn-outline-danger mr-2" v-if="progress > 0" @click="--progress">
                        Voltar
                    </button>
                    <button type="button" class="btn btn-outline-success" v-if="progress < 3" @click="++progress">
                        Continuar
                    </button>
                    <button type="button" class="btn btn-outline-primary" @click="save" v-if="progress === 3">
                        Salvar
                    </button>
                </div>
            </div>


            <div class="card mt-5" v-show="progress === 0">
                <div class="card-body">

                    <div class="row">

                        <div class="col-md-12">

                            <input type="hidden" v-model="formData.id">

                            <label class="form-label">Título da Mentoria <span class="text-danger">*</span></label>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Título da Mentoria"
                                       v-model="formData.title" required>

                                <!--                                <Editor/>-->
                            </div>

                            <div class="form-group forms mb-64pt">

                                <label class="form-label justify-content-between d-flex">
                                <span>
                                    Resumo
                                    <span class="fs-10 text-muted">Máximo 1000 caracteres</span>
                                </span>
                                </label>
                                <vue-editor ref="editor2" class="editorSize" @input="checkLength"
                                            v-model="formData.resume"
                                            required
                                            :editor-toolbar="customToolbar"/>
                                <b-badge :variant="formData.resume.length <= 700 ? 'success' : (
                            formData.resume.length > 700 && formData.resume.length <= 980 ? 'warning' : 'danger'
                        )"
                                         class="text-white mx-auto contador bold fs-12 mt-5">
                                    {{ formData.resume.length }}/1000
                                </b-badge>
                            </div>


                            <div class="form-group forms mb-64pt">
                                <div class="d-flex justify-content-between">
                                    <label class="form-label justify-content-between d-flex">
                                <span>
                                    Descrição
                                </span>
                                    </label>
                                    <div class="d-flex">
                                        <button class="btn btn-outline-info btn-sm mb-1" id="buttonGenerate"
                                                type="button"
                                                @click.stop="generateDescription">
                                            Gerar automaticamente
                                        </button>
                                        <i v-b-tooltip.hover.right="'Gerar a descrição da mentoria via inteligência artificial de acordo com o título e o resumo.'"
                                           class="fa fa-info-circle ml-2 my-auto"></i>
                                    </div>
                                </div>
                                <vue-editor ref="editor1" class="editorSize" v-model="formData.description" required
                                            :editorToolbar="customToolbar"/>
                            </div>

                            <div class="form-group forms mb-64pt">
                                <label class="form-label">Termos de Uso da Mentoria
                                    <i v-b-tooltip.hover.right="'Descreva aqui as orientações gerais da mentoria.'"
                                       class="fa fa-info-circle"></i>
                                </label>
                                <vue-editor ref="editor3" class="editorSize" v-model="formData.policy" required
                                            :editor-toolbar="customToolbar"/>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

            <div class="card mt-5" v-show="progress === 1">
                <div class="card-body">

                    <div class="row">

                        <div class="col-md-4">

                            <div class="form-group">
                                <div class="d-flex justify-content-between">
                                    <label class="form-label">Cor da Mentoria</label>
                                    <i v-b-tooltip.hover.right="'A cor que você escolher será usada como destaque principal nas mentorias, aplicando-se também aos textos e outros elementos visuais para garantir uma identidade harmoniosa e personalizada.'"
                                       class="fa fa-info-circle pointer"></i>
                                </div>
                                <input type="color" class="form-control" placeholder="Cor da Mentoria"
                                       v-model="formData.color" id="mentoring_color">
                            </div>
                        </div>
                        <div class="col-md-4">

                            <div class="form-group">
                                <label class="form-label">Formulário de Aplicação</label>
                                <select name="category" class="form-control" v-model="formData.application_form">
                                    <option value="">Selecionar</option>
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="formData.application_form == '1'">

                            <div class="form-group">
                                <label class="form-label">Formulário</label>
                                <select name="category" class="form-control" v-model="formData.application_form_id">
                                    <option value="">Selecionar</option>
                                    <option v-for="form in forms" :value="form.id"
                                            :key="`select-form-application-${form.id}`">
                                        {{ form.title }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">

                            <div class="form-group">
                                <label class="form-label">Tipo</label>
                                <select name="category" class="form-control" v-model="formData.type_id">
                                    <option value="">Selecionar</option>
                                    <option value="1">Individual</option>
                                    <option value="2">Em grupo</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="formData.type_id == '2'">
                            <div class="form-group">
                                <label class="form-label">Vagas</label>
                                <input type="number" class="form-control" placeholder="Vagas"
                                       v-model="formData.vacancies">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="form-label">Preço</label>
                                <div class="row">
                                    <div class="col">
                                        <div class="input-group form-inline">
                                            <span class="input-group-prepend"><span
                                                class="input-group-text">R$</span></span>
                                            <input type="text" class="form-control" id="price" required
                                                   @keyup="formatMoneyDecimal('price', false)" v-model="formData.price">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="d-flex justify-content-between">
                                    <label class="form-label">Palavras Chave</label>
                                    <div>
                                        <button class="btn btn-outline-info btn-sm mb-1" id="buttonGenerate"
                                                type="button"
                                                @click.stop="generateTags">
                                            Gerar automaticamente
                                        </button>
                                        <i v-b-tooltip.hover.right="'Gerar as Palavras chave de acordo com a Descrição da Mentoria'"
                                           class="fa fa-info-circle ml-1 my-auto"></i>
                                    </div>
                                </div>
                                <input type="text" class="form-control" required @keydown.enter="addTag()"
                                       placeholder="Precione Enter para confirmar" v-model="tag">
                                <span class="fs-10 text-muted ">Máximo 5</span>

                                <div class="tag mt-2" v-for="(tag, index) in tags" :key="index">
                                    {{ tag.tag }}
                                    <span class="remove-tag" @click="removeTag(index)">×</span>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div class="card mt-5" v-show="progress === 2">
                <div class="card-body">

                    <div class="row">

                        <div class="col-md-6 my-auto mb-3">
                            <label class="form-label mt-2">Logo da mentoria</label>
                            <input type="file" id="logo" class="form-control mb-3" @change="handleImageChangeLogo"
                                   required
                                   accept="image/png, image/jpeg">
                            <div class="alert alert-warning">
                                Dimensões indicadas: 600 x 600px
                            </div>
                            <div class="alert alert-danger" v-if="show_alert_logo">
                                As dimensões da sua imagem são menores que o recomendado, o que pode resultar em
                                distorções ou perda de qualidade ao ser exibida.
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <b-skeleton-img
                                v-if="loaded.indexOf('logo') < 0"
                                style="aspect-ratio: 320 / 480;"
                            />
                            <img v-show="loaded.indexOf('logo') > -1 && formData.logoUrl" :src="formData.logoUrl"
                                 id="mentoring_img"
                                 class="mentor-imagem" alt="nova_logo_mentoria" @load="loaded.push('logo')">
                            <Cropper v-if="formData.logo && !formData.logoUrl" :img="formData.logo" :proportion="1"
                                     @set-img="setImgLogo" @reset-logo="resetLogo"/>

                            <img v-else-if="!formData.logo && !formData.logoUrl" class="img-fluid"
                                 src="../../../assets/images/logo-default.png" id="mentoring_img" alt="logo_mentoria">
                        </div>

                        <div class="col-md-6 my-auto mb-lg-5 mt-lg-5 mb-3">
                            <label class="form-label mt-2">Banner Desktop da Landing Page</label>
                            <input type="file" id="desktop" class="form-control mb-3" @change="handleImageChangeDesktop"
                                   required
                                   accept="image/png, image/jpeg">
                            <div class="alert alert-warning">
                                Dimensões indicadas: 2000 x 590
                            </div>
                            <div class="alert alert-danger" v-if="show_alert_desktop">
                                As dimensões da sua imagem são menores que o recomendado, o que pode resultar em
                                distorções ou perda de qualidade ao ser exibida.
                            </div>
                        </div>
                        <div class="col-md-6 mb-lg-5 mt-lg-5 mb-3">
                            <img v-if="formData.desktopUrl" :src="formData.desktopUrl" id="mentoring_img"
                                 class="mentor-imagem" alt="nova_desktop_mentoria">
                            <Cropper v-else-if="formData.desktop && !formData.desktopUrl" :img="formData.desktop"
                                     :proportion="3.39"
                                     @set-img="setImgDesktop" @reset-logo="resetDesktop"/>
                            <img v-else class="img-fluid"
                                 src="../../../assets/images/desktop.png" id="mentoring_img" alt="desktop_mentoria">
                        </div>

                        <div class="col-md-6 my-auto mb-3">
                            <label class="form-label mt-2">Banner Mobile da Landing Page</label>
                            <input type="file" id="mobile" class="form-control mb-3" @change="handleImageChangeMobile"
                                   required
                                   accept="image/png, image/jpeg">
                            <div class="alert alert-warning">
                                Dimensões indicadas: 400 x 400
                            </div>
                            <div class="alert alert-danger" v-if="show_alert_mobile">
                                As dimensões da sua imagem são menores que o recomendado, o que pode resultar em
                                distorções ou perda de qualidade ao ser exibida.
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <img v-if="formData.mobileUrl" :src="formData.mobileUrl" id="mentoring_img"
                                 class="mentor-imagem" alt="nova_mobile_mentoria">
                            <Cropper v-else-if="formData.mobile && !formData.mobileUrl" :img="formData.mobile"
                                     :proportion="1"
                                     @set-img="setImgMobile" @reset-logo="resetMobile"/>
                            <img v-else class="img-fluid"
                                 src="../../../assets/images/desktop.png" id="mentoring_img" alt="mobile_mentoria">
                        </div>

                        <div class="col-md-6 mb-3 my-auto">
                            <label class="form-label mt-2">Vídeo de Apresentação
                                <i v-b-tooltip.hover.right="'Link deve ser do YouTube.'"
                                   class="fa fa-info-circle"></i>
                            </label>
                            <input type="text" class="form-control" v-model="formData.video" required
                                   placeholder="Insira uma URL de vídeo">
                        </div>
                        <div class="col-md-6 mb-3" style="min-height: 250px;">
                            <div class="embed-responsive embed-responsive-16by9 my-auto">
                                <iframe class="embed-responsive-item" :src="getUrlVideo(formData.video)"
                                        allowfullscreen=""></iframe>
                            </div>
                        </div>


                    </div>

                </div>
            </div>


            <div class="row mt-5" v-show="progress === 3">
                <div class="col-md-12">

                    <div class="d-flex justify-content-between"  v-if="type==='list' && (!preview && !addModule)">
                        <div>
                            <label class="form-label">Módulos</label>
                            <i style="color:black"
                               v-b-tooltip.hover.right="'Aqui é criado o plano de conteúdo da mentoria, informe os módulos e os itens(conteúdos). Podendo ser vídeo, arquivo, formulário, atividade, agendamento ou leitura.'"
                               class="fa fa-info-circle pointer"></i>
                        </div>
                        <i class="fa fa-sort pointer" title="Reordenar" v-if="type === 'list' && !preview" @click="type='drag'"></i>
                    </div>


                    <ListModules
                        v-show="type==='list' && !preview && !addModule"
                        @edit-module="previewModule"
                    />

                    <Drag
                        v-if="type==='drag'"
                        @set-type="type='list'"
                    />

                    <Preview
                        :index-module="index_module"
                        @cancel="preview=false"
                        v-if="preview && type === 'list'"
                    />

                    <AddModule
                        @cancel="addModule=false"
                        :color="formData.color"
                        :index-module="index_module"
                        v-if="addModule"
                    />

                    <button v-if="!addModule && type === 'list' && !preview" type="button" class="btn btn-outline-primary mb-24pt mb-sm-0 mt-4"
                            @click="newModule">
                        Adicionar Módulo
                    </button>
                </div>
            </div>
        </div>

        <div class="row my-3" v-if="progress < 3 || !addModule && type === 'list' && !preview">
            <div class="col text-center">
                <button type="button" class="btn btn-outline-danger mr-2" v-if="progress > 0" @click="--progress">
                    Voltar
                </button>
                <button type="button" class="btn btn-outline-success" v-if="progress < 3" @click="++progress">
                    Continuar
                </button>
                <button type="button" class="btn btn-outline-primary" id="save" @click="save" v-if="progress === 3">
                    Salvar
                </button>
            </div>
        </div>

    </Layout>

</template>

<script>
import Layout from '@/components/layout/main.vue';
import AddModule from "@/views/mentoring/register/add-module.vue";
import ListModules from "@/views/mentoring/register/list-modules.vue";
import Drag from "@/views/mentoring/register/drag.vue";
import Preview from './preview.vue';
import {VueEditor} from "vue2-editor";
import {endLoading, startLoading} from "@/components/composables/spinners";
import {toFormData, ValidateForm} from "@/components/composables/cruds";
import {moneyToFloat} from "@/components/composables/masks";
import http from "@/http"
import {getUrlVideo} from "@/components/composables/functions";
import Cropper from "@/components/base/cropper.vue";
import {messageSwal} from "@/components/composables/messages";
import _ from 'lodash';

export default {
    components: {
        Layout,
        VueEditor,
        Cropper,
        AddModule,
        ListModules,
        Drag,
        Preview,
    },

    data() {
        return {
            customToolbar: [
                [{'size': ['small', false, 'large', 'huge']}],
                ["bold", "italic"],
                [{list: "ordered"}, {list: "bullet"}],
                [{color: []}, {background: []}],
            ],
            type: 'list',
            drag: false,
            show: false,
            preview: false,
            loaded: [],
            progress: 0,
            img: '',
            imgCropped: '',
            addModule: false,
            index_module: null,
            show_alert_logo: false,
            show_alert_desktop: false,
            show_alert_mobile: false,
            copy: {
                id: 0,
                title: '',
                resume: '',
                description: '',
                policy: '',
                sections: [],
                logo: '',
                logoUrl: null,
                desktop: '',
                desktopUrl: null,
                mobile: '',
                mobileUrl: null,
                video: '',
                category_id: '',
                type_id: '',
                link: '',
                price: '',
                status: 0,
                photo: '',
                vacancies: '',
                application_form_id: '',
                application_form: '',
                color: '',
            },
            formData: {
                id: 0,
                title: '',
                resume: '',
                description: '',
                policy: '',
                sections: [],
                logo: '',
                logoUrl: null,
                desktop: '',
                desktopUrl: null,
                mobile: '',
                mobileUrl: null,
                video: '',
                category_id: '',
                type_id: '',
                link: '',
                price: '',
                status: 0,
                photo: '',
                vacancies: '',
                application_form_id: '',
                application_form: '',
                color: '#000000',
            },
            forms: [],
            tag: '',
            tags: [],
        }
    },
    methods: {

        previewModule(indexModule) {
            this.index_module = indexModule;
            this.preview = true;
        },

        editModule(indexModule) {
            this.index_module = indexModule;
            this.addModule = true;
        },

        newModule() {
            this.index_module = null;
            this.addModule = true;
        },

        getForms() {
            http.get('forms/?paginated=false')
                .then(response => {
                    this.forms = response.data.data
                    this.$emit('update-forms', response.data.data);
                })
        },

        formatMoneyDecimal(id, money = true) {
            const value = document.getElementById(id).value;
            // Remove todos os caracteres que não sejam dígitos
            if (value === '') return;

            var cleanValue = value.replace(/\D/g, '');

            // Divide o valor em parte inteira e decimal
            var integerPart = cleanValue.substring(0, cleanValue.length - 2);
            const verify = integerPart.charAt(0);
            if (parseInt(integerPart) > 1 && verify[0] === '0') integerPart = integerPart.slice(1);
            if (integerPart === '' || integerPart == 0) integerPart = '0';
            var decimalPart = cleanValue.substring(cleanValue.length - 2);
            if (decimalPart.length < 2) decimalPart = '0' + decimalPart;

            // Adiciona separador de milhar
            integerPart = integerPart.replace(/(\d)(?=(\d{3})+$)/g, '$1.');

            let format = '';
            if (integerPart === '0' && decimalPart === '00') return document.getElementById(id).value = format;

            if (money) {
                // Formata o valor como moeda (adiciona símbolo de moeda e sinal de negativo)
                format = 'R$ ' + integerPart + ',' + decimalPart;
            } else {
                // Formata o valor em decimal
                format = integerPart + ',' + decimalPart;
            }

            this.formData.price = format;
        },

        initializeEditors() {
            const editorRefs = ['editor1', 'editor2', 'editor3'];
            editorRefs.forEach(ref => {
                const editor = this.$refs[ref].quill;
                editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
                    delta.ops.forEach(op => {
                        if (op.attributes) {
                            if ('color' in op.attributes) {
                                delete op.attributes.color;
                            }
                            if ('background' in op.attributes) {
                                delete op.attributes.background;
                            }
                        }
                    });
                    return delta;
                });
            });
        },

        async goBack() {
            const deep = {
                ...this.formData,
                sections: this.$store.state.settingsMentoring.sections,
                tags: this.tags
            }
            if (_.isEqual(deep, this.copy)) {
                this.$router.go(-1);
            } else {
                const result = await messageSwal(
                    "Informações não salvas",
                    "Você realizou alteração a sua mentoria e não as salvou, tem certeza que deseja continuar?",
                    "warning"
                )

                if (result) {
                    this.$router.go(-1);
                }
            }
        },

        getUrlVideo,

        addTag() {
            if (this.tags.length > 4) {
                this.$notifyError('Máximo cinco palavras chave');
            } else {
                this.tags.push({
                    tag: this.tag
                });
                this.tag = '';
            }
        },

        removeTag(index) {
            this.tags.splice(index, 1);
        },

        handleImageChangeLogo(event) {
            const input = event.target;
            if (input.files && input.files[0]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.formData.logo = e.target.result;
                    this.formData.logoUrl = null;
                };
                reader.readAsDataURL(input.files[0]);
                const maxWidth = 600;
                const maxHeight = 600;
                if (input.width < maxWidth || input.height < maxHeight) this.show_alert_logo = true
            } else {
                this.formData.logo = null;
                this.formData.logoUrl = null;
                this.show_alert_logo = false;
            }
        },

        setImgLogo(blob) {
            this.formData.logoUrl = URL.createObjectURL(blob);
            this.formData.logo = new File([blob], "cropped-image.png", {type: 'image/png'});
        },

        resetLogo() {
            document.getElementById('logo').value = '';
            this.formData.logo = null;
            this.formData.logoUrl = null;
        },

        handleImageChangeDesktop(event) {
            const input = event.target;
            if (input.files && input.files[0]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.formData.desktop = e.target.result;
                    this.formData.desktopUrl = null
                };
                reader.readAsDataURL(input.files[0]);
                const maxWidth = 2000;
                const maxHeight = 590;
                if (input.width < maxWidth || input.height < maxHeight) this.show_alert_desktop = true
            } else {
                this.formData.desktop = null;
                this.formData.desktopUrl = null;
                this.show_alert_desktop = false;
            }
        },

        setImgDesktop(blob) {
            this.formData.desktopUrl = URL.createObjectURL(blob);
            this.formData.desktop = new File([blob], "cropped-image.png", {type: 'image/png'});
        },

        resetDesktop() {
            document.getElementById('desktop').value = '';
            this.formData.desktop = null;
            this.formData.desktopUrl = null;
        },

        handleImageChangeMobile(event) {
            const input = event.target;
            if (input.files && input.files[0]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.formData.mobile = e.target.result;
                    this.formData.mobileUrl = null;
                };
                reader.readAsDataURL(input.files[0]);
                const maxWidth = 400;
                const maxHeight = 400;
                if (input.width < maxWidth || input.height < maxHeight) this.show_alert_mobile = true
            } else {
                this.formData.mobile = null;
                this.formData.mobileUrl = null;
                this.show_alert_mobile = false;
            }
        },

        setImgMobile(blob) {
            this.formData.mobileUrl = URL.createObjectURL(blob);
            this.formData.mobile = new File([blob], "cropped-image.png", {type: 'image/png'});
        },

        resetMobile() {
            document.getElementById('mobile').value = '';
            this.formData.mobile = null;
            this.formData.mobileUrl = null;
        },

        checkLength() {
            const length = this.formData.resume.length;
            if (length > 1000) {
                this.formData.resume = this.formData.resume.substr(0, 1000);
            }
        },

        inactive() {
            startLoading('page', 'buttonGenerate');
            this.$store.commit('api/setSpinner', true);
            http.get('mentorings/change-status/' + this.formData.id)
                .then(response => {
                    this.$notifySuccess(response.data);
                    this.formData.status = 0;
                })
                .catch(errors => {
                    console.log('Inactive Mentoring: ', errors.response.data.message);
                    this.$notifyError(errors);
                })
                .finally(() => {
                    endLoading('page', 'buttonGenerate');
                    this.$store.commit('api/setSpinner', false);
                })
        },

        generateDescription() {
            const content = this.formData.title + this.formData.resume;
            this.$store.commit('api/setSpinner', true);
            http.post('mentorings/generate-resume', {content})
                .then(response => {
                    this.formData.description = response.data[0];
                })
                .catch(e => {
                    console.error('Erro na requisição GPT:', e);
                })
                .finally(() => {
                    this.$store.commit('api/setSpinner', false);
                })
        },

        generateTags() {
            const content = this.formData.description;
            this.$store.commit('api/setSpinner', true);
            http.post('mentorings/generate-tags', {content})
                .then(response => {
                    response.data.forEach(el => {
                        this.tags.push({
                            tag: el
                        })
                    })
                })
                .catch(e => {
                    console.error('Erro na requisição GPT:', e);
                })
                .finally(() => {
                    this.$store.commit('api/setSpinner', false);
                })
        },

        async save(active) {
            let result = true;


            if (result) {

                startLoading('page', 'save');
                this.$store.commit('api/setSpinner', true);
                let required = {};

                // inserir campos obrigatórios no array
                // if (active) {
                //     required = {
                //         title: 'Título',
                //         resume: 'Resumo',
                //         description: 'Descrição',
                //         policy: 'Políticas da Mentoria',
                //         logo: 'Logo',
                //         video: 'Vídeo',
                //         type_id: 'Tipo',
                //         price: 'Preço'
                //     };
                //
                //     if (this.formData.photo && this.formData.photo.length > 0) {
                //         delete required.logo;
                //     }
                //
                // } else {
                //     required = {
                //         title: 'Título',
                //     };
                // }

                // validando os campos do formulário
                if (!ValidateForm(this.$root, this.formData, required)) {
                    endLoading('page', 'save');
                    this.$store.commit('api/setSpinner', false);
                    return;
                }

                // passa o sections da store para o formData
                this.formData.sections = this.$store.state.mentoring.modules

                // validando os conteúdos
                const hasEmptyFields = this.formData.sections.some(section => {
                    return section.contents.some(content => {
                        if (
                            content.type === '' ||
                            content.subject === '' ||
                            (content.type == 1 && (content.video === null || content.video === '')) ||
                            (content.type == 2 && ((content.doc === null || content.doc === '') && content.url === '')) ||
                            (content.type == 3 && (content.form_id === null || content.form_id === '')) ||
                            (content.type == 4 && (
                                content.activity.type === null || content.activity.type === '' ||
                                content.activity.description === null || content.activity.description === '' ||
                                ((content.activity.type == 2 || content.activity.type == 3) && content.activity.alternatives.length === 0)
                            )) ||
                            (content.type == 6 && (content.description === null || content.description === ''))
                        ) {
                            this.$notifyError('Preencha todos os campos dos conteudos!');
                            endLoading('page', 'save');
                            this.$store.commit('api/setSpinner', false);
                            return true;
                        }
                        return false;
                    });
                });

                if (hasEmptyFields) {
                    return;
                }

                // inserindo os campos do formulário em um FormData
                const data = toFormData(this.formData);

                // convertendo string de preço em float
                data.append('price', moneyToFloat(document.getElementById('price').value));

                // salvando os arquivos em um array de arquivos e salvando a chave do array dentro da Módulo
                this.formData.sections.forEach((element, index) => {

                    if (element.img) data.append(`section_img[${element.id}]`, element.img)

                    element.contents.forEach((content, i) => {
                        if (content.doc) {
                            data.append(`docs[${index}${i}]`, content.doc);
                            this.formData.sections[index].contents[i].docKey = `${index}${i}`;
                        }
                    })
                })

                // convertendo as seções em json para enviar em um array
                data.append('sections', JSON.stringify(this.formData.sections));

                // salvando as tags em array
                if (this.tags.length > 0) {
                    this.tags.forEach(tag => {
                        if (!tag.id && tag.tag)
                            data.append('tags[]', tag.tag);
                    })
                }

                // se for ativar a mentoria, inserir status 1
                if (active) data.append('status', 1);

                let url = 'mentorings';

                if (this.formData.id > 0) {
                    url += `/${this.formData.id}`;
                    data.append('_method', 'PUT');
                }

                http.post(url, data)
                    .then(response => {
                        if (!active) {
                            this.$notifySuccess(response.data);
                        } else {
                            this.formData.status = 1;
                            this.$notifySuccess('Mentoria ativada com sucesso!');
                        }

                        this.copy = JSON.parse(JSON.stringify(this.formData));
                        this.$router.push('/mentorias')
                    })
                    .catch(e => {
                        console.error('Mentoring Register: ', e.response.data);
                        this.$notifyError(e)
                    })
                    .finally(() => {
                        endLoading('page', 'save');
                        this.$store.commit('api/setSpinner', false);
                    })
            }
        },

        getData(id) {
            startLoading('page', 'save');
            this.$store.commit('api/setSpinner', true);
            http.get('mentorings/' + id, {
                    headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
                })
                .then(response => {
                    const keys = Object.keys(response.data);

                    keys.forEach(key => {
                        this.formData[key] = response.data[key] ?? '';
                    })

                    this.tags = response.data.tags

                    const sections = response.data.sections;


                    this.$store.commit('mentoring/setModules', sections)
                    this.formData.sections = [];

                    if (this.formData.price) this.formData.price = this.formData.price.replace('.', ',')
                    this.show = false;

                    this.copy = JSON.parse(JSON.stringify(this.formData));
                    this.copy.sections = JSON.parse(JSON.stringify(sections));
                    this.copy.tags = JSON.parse(JSON.stringify(this.tags));

                    if (response.data.photo !== '') document.getElementById('logo').required = false;

                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    endLoading('page', 'save');
                    this.$store.commit('api/setSpinner', false);
                })
        },

    },
    mounted() {
        this.$store.commit('mentoring/resetModules');
        const id = this.$route.params.id;
        if (id) this.getData(id);
        this.initializeEditors();
    },
    created() {
        this.getForms();
    },
    computed: {
        modules() {
            return this.$store.state.mentoring.modules;
        },
    }
}
</script>

<style scoped>
.editorSize {
    height: 160px;
}

.tag {
    background-color: #efefef;
    margin: 2px;
    padding: 5px 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.remove-tag {
    margin-left: auto;
    cursor: pointer;
}

.mentor-imagem {
    width: 100%;
    height: 400px;
}
</style>
